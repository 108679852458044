'use client';

import { useEffect } from 'react';
import { usePathname } from 'next/navigation';

import { sendLog } from '@/services/logs';

export default function ScreenEnterLogger({
  name,
  additionalData,
}: {
  name: string;
  additionalData?: Record<string, unknown>;
}) {
  const pathname = usePathname();

  useEffect(() => {
    if (name)
      sendLog('ui_generic_screenEnter', {
        screen: name,
        pathname,
        ...additionalData,
      });
  }, [name, additionalData, pathname]);

  return null;
}
